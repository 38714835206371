<template>
  <v-card flat color="transparent" class="px-4 scroll" max-height="530">
    <div v-for="(item, i) in list" :key="i" @click="goto(item)">
      <v-alert
        color="orange"
        dense
        light
        text
        border="left"
        elevation="3"
        colored-border
        icon="mdi-alert"
        class="my-1"
      >
        <a>{{ item.message }}</a>
      </v-alert>
      <!-- <v-list-item-title v-text="item.message"></v-list-item-title> -->
    </div>
  </v-card>
</template>

<script>
import ALERTS from "@/graphql/Stat/ALERTS.gql";

export default {
  name: "Alertes",

  data: () => ({
    notifications: [],
    ref: false,
  }),
  watch: {
    "$store.state.changed": {
      handler() {
        if (this.$store.state.changed) this.refresh();
      },
    },
  },
  created() {
    this.refresh();
  },

  computed: {
    list() {
      return this.notifications.filter(
        (elm) =>
          (this.$store.state.alerts.map((elm) => elm.id).includes(elm.code) &&
            ((elm.code <= 6 && this.$store.state.auth.includes(elm.auth)) ||
              elm.code > 6)) ||
          this.$store.state.isadmin
      );
    },
    nbalerts() {
      // sum data in give key (property)
      return this.list ? this.list.reduce((a, b) => a + (b["nb"] || 0), 0) : 0;
    },
  },
  methods: {
    async refresh() {
      let r = await this.requette(ALERTS);
      if (r) {
        this.notifications = r.alerts;
      }
    },
    goto(item) {
      if (
        this.$store.state.auth.includes(item.auth) ||
        this.$store.state.isadmin
      ) {
        if (this.$router.currentRoute.name != item.menu)
          this.$router.push({
            name: item.menu,
            params: { filtre: item.status_name },
          });
        else {
          this.$store.dispatch("Filtre", item.status_name);
        }
      }
    },
    async requette(query, v) {
      let r;
      this.Loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.Loading = false;
        })
        .catch((error) => {
          this.Loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
  },
};
</script>
